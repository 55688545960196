// // from https://animate.style/#javascript

// const animateCSS = (node, animation, prefix = 'animate__') =>
//   // We create a Promise and return it
//   new Promise((resolve, reject) => {
//     const animationName = `${prefix}${animation}`;
//     // const node = document.querySelector(element);

//     node.classList.add(`${prefix}animated`, animationName);

//     // When the animation ends, we clean the classes and resolve the Promise
//     function handleAnimationEnd(event) {
//       event.stopPropagation();
//       node.classList.remove(`${prefix}animated`, animationName);
//       resolve('Animation ended');
//     }

//     node.addEventListener('animationend', handleAnimationEnd, {once: true});
//   });



// document.arrive('.xmy-animation', { existing: true }, function(el) {
//   const tracker = el.querySelector('.animate-me-tracker')
//   const callback = (entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         console.log('yes')
//         // animateCSS(el, 'slideInLeft')
//         el.classList.remove('shift-left', 'shift-right')
//       } else {
//         console.log('no')

//       }
//     })
//   }
//   const observer = new IntersectionObserver(callback, {root: null, threshold: 1, rootMargin: '-10% 0%' })
//   if (tracker) { observer.observe(tracker)}
// })

import sal from 'sal.js'
document.addEventListener('turbo:load', () => {
  sal({
    // once: false,
    threshold: 0.5,
    root: null
  })
})
